<template>
  <div style="height: 100%;">
    <!-- Controllers -->
    <div color="white" class="d-flex justify-end py-4 pr-6">
      <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row style="height: 80vh;">
        <v-col cols="12">
          <v-row>
            <h2 class="field-title px-3" v-text="$t('organization.sections.terms')" />
          </v-row>
          <v-row class="my-12 px-3" style="display: block !important;">
            <v-icon
              color="primary"
              size="24"
            >
              lightbulb_outline
            </v-icon>
            {{ $t('organization.settings.termsDisclaimer') }}
          </v-row>
          <v-row style="min-height: 420px;">
            <v-col cols="4">
              <terms-list
                :items="localTerms"
                @on-changed="(items) => localTerms = items"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'Terms',
  components: {
    TermsList: () => import('@/components/terms/TermsList'),
  },
  mixins: [formUtils, localeUtils],
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      localTerms: [],
    }
  },

  computed: {
    ...mapGetters({ organizationData: 'organization/data' }),
    ...mapGetters({ organizationTerms: 'organization/terms' }),
    defaultLanguage({ organizationData }) {
      return organizationData?.terms?.defaultLanguage
    },
    localDefaultLanguage({ localTerms }) {
      return localTerms.find(item => item?.isDefault)?.id
    },
  },
  watch: {
    localTerms(newLocalTerms, oldLocalTerms) {
      if (newLocalTerms.length
        && !newLocalTerms.map(term => term.id).includes(this.organizationData?.terms?.defaultLanguage)
        && !newLocalTerms.map(term => term.isDefault).includes(true)
      ) {
        this.localTerms[0].isDefault = true
      }
    },
  },
  created() {
    this.localTerms = this.organizationTerms.map(e => ({ ...e, isDefault: e.id === this.defaultLanguage }))
  },
  methods: {
    async update() {
      let response
      const { organizationId, localTerms, localDefaultLanguage } = this
      this.runAsync(() => {
        response = this.$store.dispatch('organization/updateTerms',
          {
            organizationId,
            defaultLanguage: localDefaultLanguage ?? null,
            termsList: localTerms.map(({ id, content }) => ({ id, content })),
          })
      })
      if (response) this.$store.dispatch('alert/openAlertBox', ['alertSuccess', 'Success'])
    },
  },
}
</script>

<style lang="scss">
::v-deep .tiptap-vuetify-editor__content {
  max-height: 150px;
}

.container {
  max-width: 100% !important;
}

.field-title {
  margin-bottom: 12px;
}

.field {
  padding: 4px 12px;
}

.v-input__slot {
  margin-bottom: 0 !important;
}

.v-messages.theme--light {
  display: none;
}

.image-input {
  max-width: 100% !important;
  min-height: 100px !important;
}
</style>